export const locale = {
  lang: 'fr',
  data: {
    FOOTER:{
      COPYRIGHT:"Tous droits réservés"
    },
    MENU: {
      DASHBOARD: {
        INDICATEUR: 'Indicateurs',
        VERSION: 'Versions',
        USER: 'Utilisateurs',
        VOLTIX: 'Voltix',
        COOLAC: 'Cool@c',
        DROPPY: 'Droppy',
        ADMINISTRATION:'Administration',
        SITE: 'Site',
        PARAMETRE: 'Paramètres',
        ETAT: 'Equipement',
        HO: 'Menu',
        BOX:'Boitiers',
        ENTREPRISE:'Entreprises',
        LOGOUT:'Se déconnecter',
        CHANGEPASSWORD:'Mot de passe',

        NOTIFICATION:'Notifications',
        PAGENOTFOUND:'Page non trouvée 🕵🏻‍♀️ ',
        MESSAGEPAGENOTFOUND:"Oups! 😖 L'URL demandée n'a pas été trouvée sur ce serveur",
        BTNBACK:'Retour',
        dashboard:'Tableau de bord',
        DASHBOARD:'Tableau de bord',
        EMPREINTECARBONNE:'Empreinte carbone',
        EMPLOYEE:'Employés',
        DEPLACEMENT:'Déplacement',
        DASHBOARDE:'Tableau de bord ',
        ANOMALY :'Anomaly'

      }
    },
    "Monday": 'Lundi',
    "Tuesday": 'Mardi',
    "Wednesday":'Mercredi',
    "Thursday": 'Jeudi',
    "Friday":'Vendredi',
    "Saturday": 'Samedi',
    "Sunday":'Dimanche',

    "Jan": 'janv.',
    "Feb": 'Févr.',
    "Mar":'Mars',
    "Apr": 'Avr.',
    "May":'Mai',
    "Jun": 'juin',
    "Jul":'Juil.',
    "Aug":'Août',
    "Sep": 'Sept.',
    "Oct":'Oct.',
    "Nov":'Nov.',
    "Dec": 'Déc.',

    "January": 'janvier',
    "Febuary": 'Février',
    "March":'Mars',
    "April": 'Avril.',
     
    "June": 'juin',
    "July":'Juillet',
    "August":'Août',
    "Sepember": 'Septembre',
    "October":'Octobre',
    "November":'Novembre',
    "December": 'Décembre',

    "NUMBER": "{{value}}",

    AREA:{
      AREA:'Zone',
      CostKWH:'Coût KWH',
      Child:'Sous-zone',
      DEVICES:'Liste des boitiers',
      DEVICE:'un boitier',
      AREADETAIL:'Detail de la zone',
      CHOOSSE_DEVICE: 'Boitier',
      CHOOSSE_CHANNEL:'Canal',
      AFFECT_CHANNEL:'Affecter un canal',
      DEVICEDETAIL:'Details du boitier',

      NAME:'Nom',
      SURFACE:'Surface',
      NBRPORTES:'Nombre de portes',
      NBRFENETRES:'Nombre de fenêtres',
      numOfEquipments:"Nombre d'equipements",
      numOfPeople:'Nombre de personnes',
    },
    FLOOR:{
      FLOOR:'Etage',
      NAME:'Nom',

    },
    ROOM:{
      ROOM:'Chambre',
      NAME:'Nom',
      NUMBEROFEQUIPMENT:"Nombre d'equipments",
      NUMBEROFEMPLOYEES:"Nombre d'employes"
    },
    Planning:{
      addPlanning:'Ajout',
      planning:'Planning',
      temperature:'Température',
      week_day:'Jour',
      start_hour:'Heure de début',
      end_hour:'Heure de fin',
      CapteurMouvement:'Capteur de mouvement',

      UNESEULEFOIS:'Une seule fois',
      TOUSLESJOURS:'Tous les jours',
      TOUTESLESSEMAINES:'Toutes les semaines',

      LUN:'Lun',
      MAR:'Mar',
      MER:'Mer',
      JEU:'Jeu',
      VEN:'Ven',
      SAM:'Sam',
      DIM:'Dim',

      AUJOURDHUI:"Aujourd'hui",
      CLOSE:"Fermer",
      CALENDAR:"Calendrier",
      EMPTYCALENDAR:"Calendrier vide",
    },
    VERSION:{
      VERSION_HARD:'Version hardware',
      VERSION_SOFT:'Version software',
      DATE:'Date',
      DESCRIPTION:'Description',
      PROJECT:'Projet',
      LINK:'Lien de téléchargement',
      PLACEHOLER:'Choisir le type de version',

      LINKTEXT:"Aucun fichier téléchargé pour l'instant.",
      LIST:"Liste des Software/Hardware versions"
    },
    LOGIN: {
      WELCOME: "Commencez dès aujourd'hui",
      EXPLAIN: 'Accédez à une gestion intelligente en un clic',
      DESCRIPTION: "Veuillez vous connecter à votre compte et commencer l'aventure",
      CODE: 'Veuillez renseigner le code reçu par mail',
      FORGETPSW: 'Mot de passe oublié?',
      REMEMBERME: 'Se souvenir de moi',
      SIGNIN: 'Connectez-vous',
      OTPHEADER:'Code OTP',
      OTPBODY:'Entrez le code de vérification',
      OTPBUTTON:'Confirmer',
    },
    ACTION: {
      MONTRE: 'Affiche',
      ELEMENTS: 'Eléments',
      RECHERCHE: 'Recherche',
      DATEDEBUT: 'Date de début',
      DATEFIN: 'Date de fin',
      HEUREDEBUT:"Heure",
      HEUREFIN:"Heure",
      DATE: 'Date',
      NEW: 'Nouveau',
      NEW2: 'Nouvelle',
      NEW3: 'Nouvel',
      UPDATE: 'Modifier',
      CONFIRMER: 'Confirmer',
      ANNULER: 'Annuler',
      ADD: 'Ajout',
      OBLI:'Obligatoire',
      AFFECTED:'Affecté',
      AFFECT:'Affecter',
      ACTIONS: 'Actions',
      ACTIVE: 'Activé',
      DESACTIVE: 'Désactivé',
      ACTIVER: 'Activé',
      DESACTIVER: 'Désactivé',
      RESOLU: 'Résolu',
      NONRESOLU: 'Non résolu',
      HISTORIQUE: 'Historique',
      MAP: 'Carte',
      UPDATEPSW: 'Mise à jour de mot de passe',
      UPDATE_VERSION: 'Mettre à jour la version',
      UPDATE_VERSION_AWAIT: 'Mise à jour programmée',
      REPORT:'Exporter rapport',
      Planification: 'Planifier',
      CREATED:'Créé',
      DELETE:'Supprimer',
      UPLOAD:"Télécharger",
      PLANNING:"Planning",
      QUITTER:"Quitter",
    },
    USER: {
      USER: 'Utilisateur',
      SITE_MANAGER: 'Responsable de site',
      user: 'Utilisateur',
      EMAIL: 'Mail',
      LASTNAME: 'Prénom',
      FIRSTNAME: 'Nom',
      USERNAME: "Nom d'utilisateur",
      PHONE:'Téléphone',
      PASSWORD:'Mot de passe',
      CODE:'Code',
      ETAT:'État',
      ROLE:'Rôle',
      STATUS:'Statut',
      ENTREPRISE:'Entreprise',
      ADD:'Ajouter un utilisateur',
      UPDATE:'Mise à jour utilisateur',
      ADMIN_PARTNER : 'Administrateur partenaire'
    },
    ENTREPRISE: {
      ENTREPRISES: 'Entreprises',
      ENTREPRISE: 'Entreprise',
      MANAGER: 'Gestionnaire',
      NAME: 'Nom',
      LOGO: 'Logo',
      LOGOEXEMPLE: 'Exemple de logo: https://...',
      numTVA:'Numéro  TVA',
      adressePostal:'Adresse postale',
      LIST:'Liste des entreprises'

    },
    ALARME: {
      AREA: 'pas de zone',
    },
    BOX: {
      BOX: 'Boitier',
      box: 'Boitier',
      DEVICES:'Boitiers',
      GENERATE:'Générer',
      number:'Nombre',
      AVANT: 'Avant',
      ARRIERE: 'Arriere',
      MONTE:'Monté',
      DEMONTE:'Démonter',
      REF:'Numéro de série',
      OWNER:'Propriétaire',
      NomSite: 'Nom du site',
      STATUS:'Statut',
      LASTMAJ:'Derniere MAJ logiciel',
      NOTIFICATION:'Notification',
      TIMELINE:'Chronologie',
      ALERT:'Alerte',
      ALERTS:'Alertes',
      DATE:'Date',
      LIBELLE:'Titre',
      CRITIC:'Description',
      DEFAULTVALUE1:'Valeur min par défaut ',
      DEFAULTVALUE2:'Valeur max par défaut ',
      DATEDEBUT:'Début planification ',
      DATEFIN:'Fin planification ',
      CODE:'Code',
      ALARME:'Alarme',
      INDICATEUR:'Indicateur',
      PARAMETRE:'Paramètre',
      UNITE:'Unité',
      ATTRIBUT:'Attribut',
      typeBox: 'Type du Boitier',
      etat: 'Equipement',
      ENABLE:'Activé',
      DISABLE: 'Désactivé',
      COLOR:'Couleur',
      DESCRIPTION:'Description',
      LOWER_UPPER_BOUND:'Bornes Supérieure & inférieure',
      LOWER_BOUND_ONLY:'Borne Inférieure uniquement',
      UPPER_BOUND_ONLY:'Borne Supérieure uniquement',
      BOUND:'Borne',
      LOWER_BOUND:'Borne inférieure',
      LOWER_BOUND_INCLUDED:'Borne inférieure incluse',
      TITLE:'Titre',
      UPPER_BOUND:'Borne supérieure',
      UPPER_BOUND_INCLUDED:'Borne Supérieure incluse',
      RANGES: 'GAMMES',
      TYPEALERTE:"Type d'alertes",
      PASCONNECTE:"Pas connecté",
      PAIRED:"Apparié",
      UNPAIRED:"Non apparié",
      MANUFACTURED:"Fabriqué",
      PAIREDBUTDOWN:"Apparié, non connecté",
      TYPE:'TYPE',
      ASS_THRESHOLD: "Seuil d'association à l'appareil",
      THRESHOLD: 'Seuil',
      EMPTY:'La liste des seuils pour cet appareil est vide.',

      KEEPALIVE : "Échantillonnage d’envoi",
      KEEPALIVEBUTTON : "Changer l'échantillonnage d’envoi",
      KEEPALIVELABEL : "Insérer la nouvelle valeur de l'échantillonnage d'envoi en milliseconde",

      AVEZ_VOUS_BOITIER : "Avez-vous un nouveau boitier ?",
      VERSION_BOITIER : "Version du boitier ?",
      NUMERO_BOITIER : "Numéro de boitier ?",
      PLAGE_NUMEROS_BOITIERS : "Plage de numéros de boitiers ?",
      ENTREZ_NUMERO_BOITIER : "Entrer un seul N°",
      ENTREZ_PLAGE_NUMEROS_BOITIERS : "Entrer une plage de N°",
      SN_BOITIER : "Numéro de serie du boitier",
      SN_BOITIERS : "Plage de numéros de serie du boitiers",
    },
    EQUIPMENT: {
      EQUIPMENT: 'Equipement',
      ID: 'Code',
      BRAND: 'Marque',
      DESIGNATION: 'Désignation',
      NAME: 'Nom',
      NUMBER: 'Nombre',
      POWER: 'Puissance',
      AREA: 'Zone',
      RELAY:"Relais",

      DURATION:'Durée de vie',
      SN:'Numéro de série',
      ACTADD:'Adresse',
      ACTMODULE:'Module',
      ACTVAL:'Etat',
      TYPE:'Type',
      ADDRESS:'Adresse',
      SENSOR:'Capteur',

      EQUIPMENTSTATUS:'Statut',
      TEMPERATURE:'Température',
      COOLINGCAPACITY:'Capacité de refroidissement',
    },
    COMPARE: {
      COMPARE: 'Comparer',
      AREA: 'Zones',
      AREA_CHART:"Graphique en aires",
      LINE_CHART:"Graphique linéaire",
      BAR_CHART:"Graphique en barres",
      CHART_TYPE:"Type de graphique",
      FILTER_CHART:"Graphique"
    },
    ALERTES: {
      ALERTES: 'Alertes',
      AREA: 'Areas',

    },
    SITE: {
      SITE: 'Site',
      notif:'Notifications',
      NomSite: 'Site',
      NUMEROSERIE: 'Numéro de série',
      SELLETTE1: 'SELLETTE 1',
      ETAT: 'ETAT',
      SELLETTE2: 'SELLETTE 2',
      ACTIONS: 'ACTIONS',
      NEWSITE: 'Nouveau site',
      NEWAREA: 'Nouvelle zone',
      INSTALLDATE: "Date d'installation",
      LASTCNX: 'Dernière connexion',
      etat: 'Statut',
      IMEI: 'Adresse MAC',
      VERSION: 'Version logiciel',
      MAPS: 'Position géographique',
      POSITION: 'Localisation du site',
      longitude: 'Longitude',
      latitude: 'Latitude',
      LOCALISATION: 'Localisation',
      BOITIER: 'Boitiers',
      EQUIPMENT: 'Equipement',
      CONTROL: 'Control',


      sn:'Numéro de série',
      lc:'Dernière connexion',
      planning:'Planification',
      measures:'Mesures',
      area:'Zone',

      RESOLVEDBY:'Résolu par',
      RESOLVED:'Révolu',
      NOTRESOLVED:'Non résolu',

    },
    ZONE:{ZONE:'Zone'},
    Dashboard: {
      statistique: 'Statistiques',
      vehicule: 'Véhicules',
      customers: 'Clients',
      users: 'Utilisateurs',
      free: 'Libres',
      boxes: 'Boitiers',
      MONTH:'Mois',

    },
    TOAST: {
      ADD: 'Ajouté avec succès',
      MOUNT: 'Monté avec succès',
      UNMOUNT: 'Démonté avec succès',
      AFFECT: 'Affecté avec succès',
      GENERATE: 'Généré avec succès',
      ClOSE: 'Fermé avec succès',
      UPDATE: 'Mise à jour réussie',
      logged: 'Vous vous êtes connecté avec succès',
      loggedMessage: '👋 Bonjour !, ',
      loggedMessageVoltix: '👋 Bienvenu!, sur Voltix',
      loggedMessageCoolac: '👋 Bienvenu!, sur Coolac',
      loggedMessageDroppy: '👋 Bienvenu!, sur Droppy',
      loggedMessageEmpreinteCarbonne: '👋 Bienvenu!, sur Empreinte Carbonne',

    },
    INDICATEUR: {
      borneInf: 'Borne inférieure',
      borneSup: 'Borne supérieure',
      seuil: "Seuils d'alarme",
      indicateursConfigure: 'Indicateurs configurés',
      LIST:'Liste des indicateurs'
    },
    USERS: {
      USER : "Liste des utilisateurs",
      ADMIN_PARTNER : "Liste des administrateurs partenaires"
    },
    VUEUNIFIE:{


      BIENVENUE:'Bienvenue',
      MESSAGE1:'Vous pouvez désormais suivre et controler vos Bâtiments',

      NOTEVOLTIX1:'Optimisez votre consommation,',
      NOTEVOLTIX2:'sauvez la planète et réduisez vos dépenses',

      NOTECOOLAC1:'Confort',
      NOTECOOLAC2:'Automatique, facture maîtrisée : ',
      NOTECOOLAC3:'l’IA au service de votre bien-être',

      NOTEDROPPY1:'Fuites stoppées',
      NOTEDROPPY2:'consommation maîtrisée : la technologie au service de votre sérénité',

      NOTEWHOISIN1:'Votre visage, votre accès ',
      NOTEWHOISIN2:'Sécurité et facilité ',
      NOTEWHOISIN3:'avec la reconnaissance ',
      NOTEWHOISIN4:'faciale !',
    },
    DASHBOARD: {
      GLOBALDASHBOARD:'Tableau de bord Global',
      GLOBALCONSUMPTION:'Consommation Globale',
      TOTALALERTS:'Total des alertes',
      THEMOSTCONSUMINGSITES:'Consommation par site',
      TOP3MOSTCONSUMINGSITES:'Top 3 des sites les plus consommateurs',
      TOP3HIGHALERTS:'Top 3 des alertes élevées',
      CONSUMPTIONRATEPERSITES:'Taux de consommation par site',
      RESEARCH	:'Recherche',
      GOBACK	:'Précédent',
      EXPORTREPORT:'Export rapport',
      CONSUMPTION:'Consommation',
      METER:'Compteur',
      CHANNEL:'Canal',
      AREA:'Zone',
      ROOM:'Pièce',
      MONTH:'Mois',
      CONSOSITEPARJOUR: "Consommation site / jour",
      MOYENNECONSO:"Moyenne de la semaine",
      TOTALCONSO:"Total consommé",
      VOIRPLUS: ' voir plus' ,
      TOTALCONSOMME:"Total consommé",
      COUT:"Coût",
      FILTER:"Sélectionnez un filtre",
      FILTERTIME:"Sélectionnez une heure",
      CHALLANGE:"Défi pour ce mois",
      REDUCE:"Réduisez votre facture de 5%",
      EMPRESSE_CARBONE:"Empreinte Carbone",
      TITREDATA:"Aucune donnée disponible",
      TEXTEDATA:"Désolé pour le désagrément mais nous éffectuons actuellement une maintenance.",

      TITRE2DATA:"Aucun bâtiment n'est actuellement ajouté pour cette entreprise",
      TEXTE2DATA:"Veuillez ajouter un bâtiment pour continuer.",

      CONFIRMER: "Confirmer",
      PASSER: "Passer",
      FILTER_SITE_1:"Paramètres du Site 1",
      FILTER_SITE_2:"Paramètres du Site 2",

      NO_DATA_TEMPERATURE:"Aucune donnée de température disponible pour ce site."
    },
    DASHBOARDSITE: {
      LISTOFAREAS:'Liste des zones du site',
      SITECONSUMPTION:'Consommation du site',
      THEMOSTCONSUMINGAREAS	:'Consommation par zone',
      TOP3MOSTCONSUMINGAREAS:'Top 3 des zones les plus consommatrices',
      CONSUMPTIONRATEPERAREAS	:'Taux de consommation par zone',
      CONSOAREAPARJOUR:'Consommation zone / jour',
      EQUIP: 'équipements',
      PEOPLE:'personnes',

      TITREDATA:"Aucun appareil connecté à ce site",
      TEXTEDATA:"Veuillez connecter un appareil pour afficher les données de consommation.",

      TITRE2DATA:"Aucun appareil n'est connecté à une zone de ce site",
      TEXTE2DATA:"Les appareils sont affectés au site mais ne sont pas liés à une zone.",

      AFFECT:"Ajouter un boitier pour ce site",
      DEVICE:'Details du boitier',

      COUT_DU_KWH:'Coût du kWh',
      DEVISE:'Devise',

      HOLIDAY:'Jour férié',
      HOLIDAY_DESCRIPTION:'Description',
      HOLIDAY_DATE:'Date',
    },
    EMPLOYEE:{
      EMPLOYEE: 'Employee',
      EMAIL: 'Mail',
      LASTNAME: 'Prénom',
      FIRSTNAME: 'Nom',
      USERNAME: "Nom d'Empolyee",
      PHONE:'Téléphone',
      PASSWORD:'Mot de passe',
      DISTANCE:'Distance',
      MOYENTRANSPORT:'Moyen de tansport',
      ROLE:'Rôle',
      TELETRAVAIL:'Teletravail',
      ENTREPRISE:'Entreprise',
      ADD:'Ajouter un Employee',
      adressePostal:'Adresse postale',
      LISTEEMPLOYEE:'Liste des employés',
      CAR_USE: 'Utilisation voiture',
      USER_MOTOR_USE: 'Moto',
      WALK_PERCENTAGE: 'Marche',
      BUS_USE: 'Bus',
      METRO_USE: 'Métro',
      TRAIN_USE: 'Train',
      NUMBER_OF_DAYS: 'Télétravail'
    },
    DEPLACEMENT: {
      MOYENTRANSPORT: 'Moyen de transport',
      DISTANCE: 'Distance',
      DATE: 'Date',
      EMPLOYEE: 'Employé',
      DEPLACEMENTS: 'Déplacements',
      DEPLACEMENT: 'Déplacement',
      ADD:' + Déplacement professionnel',
      LISTDEPLACEMENT : "Liste des déplacements professionnel",

    },

    DASHBOARDAREA: {
      MEASURE:'Mesure',
      PDF:'Télécharger en PDF',
      CSV:'Télécharger en CSV',

      AREACONSUMPTION:'Consommation de la zone',

      ESTIMATED:'Montant estimé de votre consommation',
      INVOICEDETAIL:'DÉTAIL DE LA FACTURE',
      LANDING:'Palier',
      AMOUNT:'Montant',
      AFFECT:"Ajouter un boitier pour cette zone",
      DEVICE:'Details du boitier',

      CONSUMPTIONCOST:'Coût de consommation (Ex. TVA)',
      TAXES	:'Taxes (TVA)',
      ALLTAXESINCLUDED:'TTC',

      DISAGGREGATION: 'Désagrégation ',

      CAMENBERT: "de Consommation par type d'équipement",
      BARCUMULEE: "Consommation Totale par type d'équipement",
      BAR: "Consommation par type d'équipement",
      COST:'Coût',

      CONTROL:'Contrôles',

      TITREDATA:"Aucun zone n'est actuellement ajouté pour ce site",
      TEXTEDATA:"Veuillez ajouter une zone pour continuer.",

      TITRE2DATA:"Aucun appareil connecté à cette zone",
      TEXTE2DATA:"Veuillez connecter un appareil pour afficher les données de consommation."
    },
    AFFECT: {
      AFFECTUSER:'Affecter un utilisateur à une zone',
      AFFECTTOSITE:"Affecter l'utilisateur au site",
      SELECTZONE :'Sélectionner la zone',
      SELECTSITE:'Choisir le site',
      AFFECTTOAREA:'Affecter',
      MANAGER:'Responsable'
    },
    THRESHOLDS: {
      LIST:'Paramètres'
    },
    DEVICES: {
      LIST:'Liste des boitiers'
    },
    DELETE:{
      INDICATOR:"Voulez-vous vraiment supprimer cet indicateur ?",
      USER:"Voulez-vous vraiment supprimer cet utilisateur ?",
      PARAMETRE:"Voulez-vous vraiment supprimer ce paramètre ?",
      VERSION:"Voulez-vous vraiment supprimer cette version ?",
      AREA:"Voulez-vous vraiment supprimer cette zone ?",
      SITE:"Voulez-vous vraiment supprimer ce site ?",
      EQUIPEMENT:"Voulez-vous vraiment supprimer cet équipement ?",

      CONFIRMOFF:"Voulez-vous vraiment éteindre cet équipement ?",
      CONFIRMON:"Voulez-vous vraiment allumer cet équipement ?",
      DEMANDEON : "Voulez-vous vraiment demander d'allumer cet équipement ?",
      DEMANDEOFF : "Voulez-vous vraiment demander d'éteindre cet équipement ?",
      YES:"Oui",
      NO:"Non"
    },


    MEASURE: {
      Active_pow:'Puissance active',
      ph_to_line_volt:'Tension entre phases',
      Current:'Courant',
      reactive_pow:'Puissance réactive',
      apparent_pow:'Puissance apparente',
      pow_factor:'Facteur de puissance',
      freq:'Fréquence',
      tot_reactive_pow:'Puissance réactive totale',
      tot_apparent_pow:'Puissance apparente totale',
      tot_active_energ:'Énergie active totale',
      tot_reactive_energ:'Énergie réactive totale',
      tot_pow_factor:'Facteur de puissance total',
      ph1_to_ph2_volt:'Tension entre phase 1 et phase 2',
      ph2_to_ph3_volt:'Tension entre phase 2 et phase 3',
      ph3_to_ph1_volt:'Tension entre phase 3 et phase 1',
      tot_active_pow:'Puissance active totale',
      voltage:'Tension',
      ActivePower : 'Puissance active',
      ReactivePower : 'Puissance réactive',
      ApparentPower : 'Puissance apparente',
      PowerFactor : 'Facteur de puissance',
      NoValues:'Aucune valeur',
      TotalEnergie:'Énergie totale',
      TotalPower:'Puissance totale',
      PhaseToPhase: 'Phase à phase'
    },
    RESOLVED: {
      ALERTE: "Voulez-vous vraiment confirmer que ce problème est résolu ?",
      YES: "Oui",
      NO: "Non"
    },
    ANOMALY: {
      ANOMALY:"ANOMALIE",
      SEVERITY_HIGH:  "Gravité Élevée" ,
      SEVERITY_MEDIUM: "Gravité Moyenne",
      SEVERITY_LOW: "Gravité Faible",
      SEVERITY:"GRAVITÉ",
      NAME:"TITRE",
      DESCRIPTION:"DESCRIPTION",
      NEW :"Ajouter une anomalie",
      TYPE: "Type ",
      ADD:"Ajout d'Anomalie",
      UPDATE:"Modification d'Anomalie",
      LIST:" Liste d'anomalies"
    }
  },

};
