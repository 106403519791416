import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {TranslateModule} from '@ngx-translate/core';
import {ContextMenuModule} from '@ctrl/ngx-rightclick';

import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {CardSnippetModule} from '@core/components/card-snippet/card-snippet.module';

import {coreConfig} from 'app/app-config';
import {AuthGuard} from 'app/auth/helpers/auth.guards';
import {ErrorInterceptor, JwtInterceptor} from 'app/auth/helpers';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';
//import { UiSwitchModule } from 'ngx-ui-switch';
import {ChartsModule} from 'ng2-charts';
import {SiteService} from "./main/voltix/site/site.service";
import {SwiperModule} from "swiper/angular";
import { DashboardComponent } from './main/empreinteCarbone/dashboard/dashboard.component';
import { EmployeeComponent } from './main/empreinteCarbone/employee/employee.component';
import { DeplacementComponent } from './main/empreinteCarbone/deplacement/deplacement.component';
import {NgApexchartsModule} from "ng-apexcharts";
import { CompareZonesComponent } from './main/voltix/compare-zones/compare-zones.component';
import { AlertesComponent } from './main/voltix/alertes/alertes.component';
import { AnomalyComponent } from './main/administration/anomaly/anomaly.component';

const appRoutes: Routes = [
    {
        path: 'vueUnifie',
        loadChildren: () => import('./main/administration/vueUnifie/vueUnifie.module').then(m => m.VueUnifieModule),
        canActivate: [AuthGuard]
    },
    //*****************************voltix*********************************************
    {
        path: 'dashboard',
        loadChildren: () => import('./main/voltix/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboardSite',
        loadChildren: () => import('./main/voltix/site/dashboardSite/dashboardSite.module').then(m => m.DashboardSiteModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboardArea',
        loadChildren: () => import('./main/voltix/site/dashboardArea/dashboardArea.module').then(m => m.DashboardAreaModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'siteV',
        loadChildren: () => import('./main/voltix/site/site.module').then(m => m.SiteModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'compareZones',
        loadChildren: () => import('./main/voltix/compare-zones/compare-zones.module').then(m => m.CompareZonesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'boxV',
        loadChildren: () => import('./main/voltix/box/box.module').then(m => m.BoxModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'indicatorV',
        loadChildren: () => import('./main/voltix/indicateur/indicateur.module').then(m => m.IndicateurModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'versionV',
        loadChildren: () => import('./main/voltix/version/version.module').then(m => m.VersionModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'settingV',
        loadChildren: () => import('./main/voltix/parametre/parametre.module').then(m => m.ParametreModule),
        canActivate: [AuthGuard]
    },

    //*****************************droppy*********************************************
    {
        path: 'siteD',
        loadChildren: () => import('./main/droppy/site/site.module').then(m => m.SiteModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'boxD',
        loadChildren: () => import('./main/droppy/box/box.module').then(m => m.BoxModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'indicatorD',
        loadChildren: () => import('./main/droppy/indicateur/api.module').then(m => m.ApiModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'versionD',
        loadChildren: () => import('./main/droppy/version/version.module').then(m => m.VersionModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'settingD',
        loadChildren: () => import('./main/droppy/parametre/api.module').then(m => m.ApiModule),
        canActivate: [AuthGuard]
    },
    //*****************************COOL@C*********************************************
    {
        path: 'siteC',
        loadChildren: () => import('./main/coolac/site/site.module').then(m => m.SiteModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'boxC',
        loadChildren: () => import('./main/coolac/box/box.module').then(m => m.BoxModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'indicatorC',
        loadChildren: () => import('./main/coolac/indicateur/api.module').then(m => m.ApiModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'versionC',
        loadChildren: () => import('./main/coolac/version/version.module').then(m => m.VersionModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'settingC',
        loadChildren: () => import('./main/coolac/parametre/api.module').then(m => m.ApiModule),
        canActivate: [AuthGuard]
    },
    //*****************************Alertes *********************************************
    {
        path: 'alertes',
        loadChildren: () => import('./main/voltix/alertes/alertes.module').then(m => m.AlertesModule),
        canActivate: [AuthGuard]
    },
    //*****************************Empreinte Carbonen *********************************************
    {
        path: 'dashboardE',
        loadChildren: () => import('./main/empreinteCarbone/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'employee',
        loadChildren: () => import('./main/empreinteCarbone/employee/employee.module').then(m => m.EmployeeModule),
        canActivate: [AuthGuard]
    },    {
        path: 'deplacement',
        loadChildren: () => import('./main/empreinteCarbone/deplacement/deplacement.module').then(m => m.DeplacementModule),
        canActivate: [AuthGuard]
    },
    //*****************************ADMINISTRATION*********************************************

    {
        path: 'user',
        loadChildren: () => import('./main/administration/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'company',
        loadChildren: () => import('./main/administration/entreprise/entreprise.module').then(m => m.EntrepriseModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'anomaly',
        loadChildren: () => import('./main/administration/anomaly/anomaly.module').then(m => m.AnomalyModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'authentication',
        loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)

    },

    {
        path: 'layout',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
    },

    {
        path: '',
        redirectTo: '/vueUnifie',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/layout/miscellaneous/error' //Error 404 - Page not found
    }
];

@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [

        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {useHash: true}), //{ scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy'},

        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
        ChartsModule,
        SwiperModule,
        NgApexchartsModule,
        /*UiSwitchModule.forRoot({
          size: 'small',
          color: 'rgb(0, 189, 99)',
          switchColor: '#80FFA2',
          defaultBgColor: '#00ACFF',
          defaultBoColor : '#476EFF',
          checkedLabel: 'on',
          uncheckedLabel: 'off'
        })*/
    ],

    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        SiteService
    ],
    entryComponents: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
