import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {AuthenticationService} from '../../../auth/service';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SiteService {
    private selectedAreaSubject = new BehaviorSubject<any | null>(null);
    selectedArea$ = this.selectedAreaSubject.asObservable();
    private areaData: any;

    constructor(private _httpClient: HttpClient, private auth: AuthenticationService) {
    }

    ingoreList = [5, 6, 7];
    ingoreListTitle = [11, 10, 7, 5, 8, 6, 12, 9];


    //event parent to child
    private notify = new Subject<any>();
    notifyObservable = this.notify.asObservable();

    public notifyOther(data: any) {
        if (data) {
            this.notify.next(data);
        }
    }

    getFromMongo(debut, fin) {
        return this._httpClient.get(`${environment.url}boitier/taj2?date1=${debut}&date2=${fin}`).toPromise();
    }

    allmesuresGlobale(data) {
        return this._httpClient.post(`${environment.url}measure/allMesuresGlobaleByDate`, data).toPromise();
    }

    allmesuresConsommation(id) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let options = {headers: headers};
        return this._httpClient.post(`${environment.url}mesure/allMesuresConsommationByDate`, id, options).toPromise();
    }
    allmesuresConsommationMonth(id) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let options = {headers: headers};
        return this._httpClient.post(`${environment.url}mesure/allMesuresConsommationByDateMonth`, id, options).toPromise();
    }
    async exportExcel(id) {
        return this._httpClient.post(`${environment.url}measures/exportExcelGlobal`, id, {responseType: 'arraybuffer' as 'json'}).toPromise();
    }

    getListSiteDash(idClient,num, numberElement,recherche,project) {

        return this._httpClient.get(`${environment.url}sites?idClient=${idClient}&nombreElement=${numberElement}&numPage=${num}&recherche=${recherche}`).toPromise();
    }

    getListAreas(siteId, project) {
        return this._httpClient.get(`${environment.url}areas?siteId=${siteId}&project=${project}`).toPromise();
    }

    getDeviceSite(id, project) {
        return this._httpClient.get(`${environment.url}sites/devices?id=${id}&project=${project}`).toPromise();
    }
    getArea(areaId) {
        return new Observable(observer => {
            this._httpClient.get(`${environment.url}areas/details?areaId=${areaId}`).subscribe(
                (data: any) => {
                    observer.next(data);
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                }
            );
        });
    }

    listSiteRecherche(num, numelement, recherche) {
        return this._httpClient.get(`${environment.url}sites/list?nombreElement=${numelement}&numPage=${num}&recherche=${recherche}`).toPromise();
    }
    areas(siteId) {
        return this._httpClient.get(`${environment.url}areas?siteId=${siteId}`).toPromise();
    }

    addSite(data) {
        return this._httpClient.post(`${environment.url}sites`, data).toPromise();
    }
    updateSite(data) {
        return this._httpClient.put(`${environment.url}sites/${data.id}`, data).toPromise();
    }
    addAreaFunction(data) {
        return this._httpClient.post(`${environment.url}areas/${data.siteId}`, data).toPromise();
    }
    updateAreaFunction(data) {
        return this._httpClient.put(`${environment.url}areas/${data.id}`, data).toPromise();
    }
    addPlanification(plan) {
        return this._httpClient.post(`${environment.url}plannings/save`, plan).toPromise();
    }

    getBoitier(id, project) {
        return this._httpClient.get(`${environment.url}sites/devices?id=${id}&project=${project}`).toPromise();
    }
    getBoitierByArea(idArea, project) {
        return this._httpClient.get(`${environment.url}devices/devicesByArea?idArea=${idArea}&project=${project}`).toPromise();
    }
    followers(idSite) {
        return this._httpClient.post(`${environment.url}sites/followers`, {"id": idSite}).toPromise();
    }

    follow(data) {
        return this._httpClient.post(`${environment.url}sites/follow`, data).toPromise();
    }

    followersArea(idArea) {
        return this._httpClient.post(`${environment.url}areas/followers`, {"id": idArea}).toPromise();
    }

    followArea(data) {
        return this._httpClient.post(`${environment.url}areas/follow`, data).toPromise();
    }

    manage(data) {
        return this._httpClient.post(`${environment.url}sites/manager`, data).toPromise();
    }

    pairing(data) {
        return this._httpClient.post(`${environment.url}sites/pairing`, data).toPromise();
    }

    pairingOnArea(data) {
        return this._httpClient.post(`${environment.url}areas/pairing`, data).toPromise();
    }

    listBoitierNotPaired(num, numelement, project) {
        return this._httpClient.get(`${environment.url}devices/notPaired?nombreElement=${numelement}&numPage=${num}&project=${project}`).toPromise();
    }
    listBoitierNotPairedOnArea(num, numelement,siteId,project) {
        return this._httpClient.get(`${environment.url}devices/notPairedArea?nombreElement=${numelement}&numPage=${num}&siteId=${siteId}&project=${project}`).toPromise();
    }

    deleteSite(id) {
        return this._httpClient.delete(`${environment.url}sites/${id}`, ).toPromise();
    }

    deleteArea(id) {
        return this._httpClient.delete(`${environment.url}areas/${id}`).toPromise();
    }
    allmesures(data) {
        return this._httpClient.post(`${environment.url}measures/allMeasuresByDate`, data).toPromise();
    }

    lastMesure(data) {
        return this._httpClient.post(`${environment.url}measures/lastMeasures`, data).toPromise();
    }

    metersList(data) {
        return this._httpClient.post(`${environment.url}measures/meters`, data).toPromise();
    }

    metersAreaList(data) {
        return this._httpClient.post(`${environment.url}measures/metersAreaList`, data).toPromise();
    }
    channelsList(data) {
        return this._httpClient.post(`${environment.url}measures/channels`, data).toPromise();
    }

    private messageSource = new BehaviorSubject<any>('default message');
    currentMessage = this.messageSource.asObservable();
    changeMessage(message) {
        this.messageSource.next(message)
    }
    getCountries(): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.url}sites/countries`);
    }
    listAreaBySite(siteId,num, numelement,recherche) {
        return this._httpClient.get(`${environment.url}areas?nombreElement=${numelement}&numPage=${num}&siteId=${siteId}&recherche=${recherche}`).toPromise();
    }
    listChildBuParentId(parentId,num, numelement) {
        return this._httpClient.get(`${environment.url}areas/child?nombreElement=${numelement}&numPage=${num}&parentId=${parentId}`).toPromise();
    }
    listAreaNotAssigned(siteId,num, numelement,userId) {
        return this._httpClient.get(`${environment.url}areas/areaNotAssignedToUser?nombreElement=${numelement}&numPage=${num}&siteId=${siteId}&userId=${userId}`).toPromise();
    }
    listDevices(project) {
        return this._httpClient.get(`${environment.url}devices/notPairedSite?project=${project}`).toPromise();
    }

    listDevicesForArea(project,siteId) {
        return this._httpClient.get(`${environment.url}devices/notPairedArea?siteId=${siteId}&project=${project}`).toPromise();
    }
    affectDeviceToSite(pairingData) {
        return this._httpClient.post(`${environment.url}sites/pairing`,pairingData).toPromise();
    }
    affectDeviceToArea(pairingData) {
        return this._httpClient.post(`${environment.url}areas/pairing`,pairingData).toPromise();
    }
    isManager (userId, siteId){
        return this._httpClient.get(`${environment.url}users/managed?userId=${userId}&siteId=${siteId}`).toPromise();

    }
    async exportPDF(data) {
        return this._httpClient.post(`${environment.url}measures/exportPDFGlobal`, data, {responseType: 'arraybuffer' as 'json'}).toPromise();
    }

    getListHolidays(siteId) {
        return this._httpClient.get(`${environment.url}holidays?siteId=${siteId}`).toPromise();
    }
    addHoliday(holiday) {
        return this._httpClient.post(`${environment.url}holidays`, holiday).toPromise();
    }

    updateHoliday(holiday) {
        return this._httpClient.put(`${environment.url}holidays`, holiday).toPromise();
    }

    deleteHoliday(holidayId) {
        return this._httpClient.delete(`${environment.url}holidays?holidayId=${holidayId}`).toPromise();
    }

}
